<template>
  <b-overlay :show="loading">
    <b-row class="px-1 match-height">
      <b-col v-for="(stats, index) in dashboardStats" :key="`stats-${index}`" md="3">
        <b-card>
           <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="primary"
                >
                  <feather-icon
                    size="24"
                    :icon="stats.icon"
                  />
                </b-avatar>
              </b-media-aside>

              <b-media-body class="my-auto">
                <b-card-text class="font-small-3 mb-0 text-right">
                  {{ stats.title }}
                </b-card-text>
                <h1 class="font-weight-bolder mb-0 text-right">
                  {{ stats.subtitle }}
                </h1>
              </b-media-body>
            </b-media>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { get } from "lodash"
import { 
  BOverlay,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BImg,
  BCardSubTitle,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody, 
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BImg,
    BCardSubTitle,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody, 
  },
  data() {
    return {
      rawDashboardStats: {
        job_seekers: 0,
        active_jobs: 0,
        job_providers: 0
      },
      loading: false
    }
  },
  computed: {
    dashboardStats() {
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 'Active Jobs',
          subtitle: get(this.rawDashboardStats, 'active_jobs'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: 'Job Seekers',
          subtitle: get(this.rawDashboardStats, 'job_seekers'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BriefcaseIcon',
          color: 'light-primary',
          title: 'Job Providers',
          subtitle: get(this.rawDashboardStats, 'job_providers'),
          customClass: 'mb-2 mb-xl-0',
        },
      ]
    }
  },
  created() {
    this.fetchDashboardStats()
  },
  methods: {
    async fetchDashboardStats() {
      try {
        this.loading = true; 

        const request = await this.useJwt().adminService.fetchDashboardStatsData({});
        const { data } = request.data;
        
        this.rawDashboardStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';

  h3 {
    color: #459042;
  }
</style>
